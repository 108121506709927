import Axios from "axios";
import token from "./setToken";

export default {

  async selectList() {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/M12CitySelectList", token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("12都市区分一覧を取得できません[selectList]");
      throw error;
    }
  },

};
