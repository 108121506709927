<template>
  <v-form
        ref="form"
        v-model="valid"
        lazy-validation
  >
    <v-container style="mix_width: 1200px !important;">
      <v-row>
        <v-col cols="2">
          <h2>事業所会員編集</h2>
        </v-col>
        <!--
        <v-col v-if="attribute == 0" cols="3" align-self="center">
          <span v-if="memberData.divisionCompany">情報の変更は日本冷蔵倉庫協会までご依頼ください。</span>
          <span v-else>情報の変更は所属の都道府県協会までご依頼ください。</span>
        </v-col>
         -->
        <v-spacer></v-spacer>
        <!-- 上下にボタンを着ける -->
        <v-col cols="3" class="left">
          <div v-if="!isDisp">
            <v-btn v-if="!isDelete"
                  class="btn_font primary" width="200" v-on:click="registData(mode)" :disabled="loading"
            >保存</v-btn>
            <v-btn v-else
                  class="btn_font secondary" width="200" v-on:click="registData(mode)" :disabled="loading"
            >削除</v-btn>
          </div>
        </v-col>
        <v-col cols="4" class="left">
          <v-btn class="btn_font primary ml-10" width="200" v-on:click="cancel()" :disabled="loading"
        >{{!isDisp ? 'キャンセル' : '戻る'}}</v-btn>
        </v-col>
      </v-row>

      <!-- ---------- 会員区分 ---------- -->
      <v-row>
        <v-col>
          <h4>会員区分</h4>
        </v-col>
      </v-row>
      <v-row class="pt-0 mt-0">
        <v-col class="pt-0 mt-0 d-flex align-center" cols="8">
          <v-checkbox class="mr-5" label="会員企業"
              v-model="memberData.divisionCompany"
              dense :readonly="isDelete"
          />
          <v-checkbox class="mr-5" label="事業所会員Ⅰ"
              v-model="memberData.divisionIndustry1"
              dense :readonly="isDelete"
          />
          <v-checkbox class="mr-5" label="事業所会員Ⅱ"
              v-model="memberData.divisionIndustry2"
              dense :readonly="isDelete"
          />
          <v-checkbox class="mr-5" label="事業所会員Ⅲ"
              v-model="memberData.divisionIndustry3"
              dense :readonly="isDelete"
          />
          <v-checkbox class="mr-5" label="賛助会員"
              v-model="memberData.divisionSupporter"
              dense :readonly="isDelete"
          />
          <v-checkbox label="日冷協会員" v-model="memberData.divisionFrozen" dense :readonly="isDelete" />
        </v-col>
        <v-col cols="2">
          <v-select label="変更区分"
                v-model="memberData.changeCategory"
                :items="changeCategory"
                item-text="text"
                item-value="value"
                dense :readonly="isDelete"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field label="変更 備考"
                v-model="memberData.changeRemarks"
                dense
          ></v-text-field>
        </v-col>

      </v-row>

     <!-- ---------- 会社情報 ---------- -->
      <v-row>
        <v-col>
          <h4>会社情報</h4>
        </v-col>
      </v-row>
      <v-row class="pt-0 mt-0">
        <v-col cols="10" class="campany-flex">
          <v-text-field label="登録番号" class="w200"
                v-model="memberData.companyId"
                dense readonly
                :loading="loading"
          ></v-text-field>

          <div v-if="mode == 'new'">
            <v-autocomplete
                  label="会社"
                  v-model="memberData.companyId"
                  :items="companyList"
                  item-text="company_name"
                  item-value="company_id"
                  dense
                  :rules="[requiredIf(() => registType == 0)]"
                  :readonly="mode != 'new'"
                  :loading="loading"
                  @change="changeCompanyData(memberData.companyId)"
                  class="w400"
            ></v-autocomplete>
          </div>
          <div v-else>
            <v-text-field
                  label="会社" class="w400"
                  v-model="memberData.companyName"
                  dense readonly
                  :loading="loading"
            ></v-text-field>
          </div>

          <v-text-field label="会社名カナ" class="w400"
                v-model="memberData.companyKana"
                dense readonly
                :loading="loading"
          ></v-text-field>
          <NumberTextField label="資本金" class="w200 right-input"
                v-model="memberData.capital"
                dense readonly
                :loading="loading"
                suffix="千円"
          ></NumberTextField>
        </v-col>
        <v-col cols="2">
          <v-btn v-if="mode == 'new'" class="btn_font primary" v-on:click="showCompanyEdit()" :disabled="loading" >
            <v-icon left>mdi-plus-circle</v-icon>新規会社登録
          </v-btn>
        </v-col>
      </v-row>

      <!-- ---------- 事業所情報 ---------- -->
      <v-row class="mt-0">
        <v-col>
          <h4>事業所情報</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field label="会員ID"
                v-model="memberData.memberNo"
                dense
                readonly
                :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-checkbox label="会員"
                v-model="memberData.member"
                dense
                :readonly="isDelete"
          ></v-checkbox>
        </v-col>
        <!-- <v-col cols="2">
          <v-checkbox label="閉鎖廃止"
                v-model="memberData.closed"
                dense :readonly="isDelete"
          ></v-checkbox>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field label="事業所名"
                v-model="memberData.officeName"
                :maxlength="100"
                :counter="100"
                dense
                :readonly="isDelete"
                :loading="loading"
          ></v-text-field>
        </v-col>
        <!--
        <v-col v-if="isDisp" cols="4">
          <v-select label="事業所名"
                v-model="memberNo"
                :items="memberList"
                item-text="officeName"
                item-value="memberNo"
                dense
                :loading="loading"
                @change="loadList()"
          ></v-select>
        </v-col>
        -->
        <v-col cols="4">
          <v-text-field label="事業所名カナ"
                v-model="memberData.companyOfficeKana"
                :maxlength="100"
                :counter="100"
                dense
                :readonly="isDelete"
                :loading="loading"
          ></v-text-field>
          </v-col>
        <v-col cols="2">
          <v-select label="営業自家用区分"
                v-model="memberData.usage"
                :items="usage"
                dense
                :readonly="isDelete"
                :loading="loading"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <!-- <v-checkbox label="倉庫証券発券"
                v-model="memberData.ticket"
                dense
                :readonly="isDelete"
          ></v-checkbox> -->
          <v-select label="倉庫証券発券"
                v-model="memberData.ticket"
                :items="hakkenKubun"
                dense
                :readonly="isDelete"
                :loading="loading"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field label="郵便番号"
                v-model="memberData.postCode"
                dense
                :readonly="isDelete"
                :loading="loading"
                :maxlength="10"
                :counter="10"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select label="都道府県"
                v-model="memberData.prefId"
                :items="prefList"
                item-text="text"
                item-value="value"
                :rules="[required]"
                dense :readonly="isDelete"
                @change="getNewNo(memberData.prefId); resetDistrict();"
                :loading="loading"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field label="住所"
                v-model="memberData.address"
                :maxlength="200"
                :counter="200"
                :rules="[required]"
                dense :readonly="isDelete"
                :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="ビル名"
                v-model="memberData.bldg"
                :maxlength="100"
                :counter="100"
                dense :readonly="isDelete" :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field label="URL"
              v-model="memberData.url"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-select label="12都市区分"
              v-model="memberData.cityDataSection"
              :items="city12List"
              dense :readonly="isDelete" :loading="loading"
              :clearable="!isDelete"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select label="集計地区"
              v-model="memberData.district"
              :items="getFilDistrictList"
              dense :readonly="isDelete" :loading="loading"
              :clearable="!isDelete"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select label="グループ名"
              v-model="memberData.groupName"
              :items="groupNameList"
              dense :readonly="isDelete" :loading="loading"
              :clearable="!isDelete"
          ></v-select>
          <!-- <v-text-field label="グループ名"
              v-model="memberData.groupName"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field> -->
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="4">
          <h4>挨拶状/総会開催通知等送付先</h4>
        </v-col>
        <v-col cols="4">
          <h4>県協会確認(会員事業所責任者)</h4>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="2">
          <v-text-field label="会社代表者役職"
              v-model="memberData.coRepresentativePost"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="会社代表者名"
              v-model="memberData.coRepresentativeName"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="会社代表者カナ"
              v-model="memberData.coRepresentativeKana"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="事業所代表者役職"
              v-model="memberData.representativePost"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="事業所代表者名"
              v-model="memberData.representativeName"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="事業所代表者カナ"
              v-model="memberData.representativeKana"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-text-field label="電話番号"
              v-model="memberData.tel"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="FAX番号"
              v-model="memberData.fax"
              dense :readonly="isDelete" :loading="loading"
              :maxlength="100"
              :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <DatePicker label="竣工年月日"
              v-model="memberData.completionDate"
              format="YYYY/M/D"
              selectType="date"
              :clearable="!isDelete"
              :loading="loading"
          ></DatePicker>
        </v-col>

      </v-row>

      <!-- ---------- 日冷倉協 ---------- -->
      <v-row>
        <v-col>
          <h4>日冷倉協</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select label="日冷倉協会名"
                v-model="memberData.associationId"
                :items="associationList"
                item-text="association_name"
                item-value="association_id"
                dense :readonly="isDelete || attribute == 1" :loading="loading"
                :clearable="!isDelete && attribute != 1"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <DatePicker
              label="入会日"
              v-model="memberData.joinStart"
              format="YYYY/M/D"
              selectType="date"
              :readonly="isDelete" :loading="loading"
              :clearable="!isDelete"
          ></DatePicker>
        </v-col>
        <v-col cols="2">
          <DatePicker
              label="退会日"
              v-model="memberData.joinEnd"
              format="YYYY/M/D"
              selectType="date"
              :readonly="isDelete" :loading="loading"
              :clearable="!isDelete"
          ></DatePicker>
        </v-col>
        <v-col cols="2">
          <v-checkbox label="閉鎖廃止"
                v-model="memberData.closed"
                dense :readonly="isDelete"
          ></v-checkbox>
        </v-col>
        <v-col cols="2">
          <v-checkbox label="休会"
                v-model="memberData.kyukai"
                dense :readonly="isDelete"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- ---------- 日冷協 ---------- -->
      <v-row>
        <v-col>
          <h4>日冷協</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select label="冷事協会名"
                v-model="memberData.frozenAssociationId"
                :items="associationList2"
                item-text="association_name"
                item-value="association_id"
                dense :readonly="isDelete || attribute == 1" :loading="loading"
                :clearable="!isDelete && attribute != 1"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <DatePicker
              label="入会日"
              v-model="memberData.frozenJoinStart"
              format="YYYY/M/D"
              selectType="date"
              :readonly="isDelete || attribute == 1" :loading="loading"
              :clearable="!isDelete && attribute != 1"
          ></DatePicker>
        </v-col>
        <v-col cols="2">
          <DatePicker
              label="退会日"
              v-model="memberData.frozenJoinEnd"
              format="YYYY/M/D"
              selectType="date"
              :readonly="isDelete || attribute == 1" :loading="loading"
              :clearable="!isDelete && attribute != 1"
          ></DatePicker>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>運輸局登録</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-data-table
                :hide-default-header="true"
                :hide-default-footer="true"
                dense
                class="soko-info-table table-border mb-5"
          >
            <template v-slot:body="{}">
              <thead>
                <tr>
                  <th width="40%" colspan=2 class="center">
                    所管容積：㎥（Ｆ級）
                  </th>
                  <th width="40%" colspan=2 class="center">
                    <span class="text-subtitle-1 font-weight-black">所管容積：㎥（Ｃ級）</span>
                  </th>
                  <th width="20%" class="center">
                    <span class="text-subtitle-1 font-weight-black">ＦＣ級合計</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Ｆ１級 -20～30℃</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.f1Volume"
                          :readonly="isDelete" hide-details
                    ></Numbertextfield>
                  </td>
                  <th>Ｃ１級 -10～20℃</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.c1Volume"
                          :readonly="isDelete" hide-details
                    ></NumberTextField>
                  </td>
                  <th></th>
                </tr>
                <tr>
                  <th>Ｆ２級 -30～40℃</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.f2Volume"
                          :readonly="isDelete" hide-details
                    ></NumberTextField>
                  </td>
                  <th>Ｃ２級 -2～10℃</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.c2Volume"
                          :readonly="isDelete" hide-details
                    ></Numbertextfield>
                  </td>
                  <th></th>
                </tr>
                <tr>
                  <th>Ｆ３級 -40～50℃</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.f3Volume"
                          :readonly="isDelete" hide-details
                    ></NumberTextField>
                  </td>
                  <th>Ｃ３級 +10～-2℃</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.c3Volume"
                          :readonly="isDelete" hide-details
                    ></Numbertextfield>
                  </td>
                  <th></th>
                </tr>
                <tr>
                  <th>Ｆ４級 -50℃～</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.f4Volume"
                          :readonly="isDelete" hide-details
                    ></NumberTextField>
                  </td>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th class="right">Ｆ級合計</th>
                  <td>
                    <NumberTextField
                          v-model.number="fVolumeCalc"
                          readonly hide-details
                    ></Numbertextfield>
                  </td>
                  <th class="right">Ｃ級合計</th>
                  <td>
                    <NumberTextField
                          v-model.number="cVolumeCalc"
                          readonly hide-details
                    ></NumberTextField>
                  </td>
                  <td>
                    <NumberTextField
                          v-model.number="volumeCalc"
                          readonly hide-details
                    ></NumberTextField>
                  </td>
                </tr>
                <tr>
                  <th>内 移動ラック：㎥</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.moveF"
                          :readonly="isDelete" hide-details
                    ></NumberTextField>
                  </td>
                  <th>内 移動ラック：㎥</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.moveC"
                          :readonly="isDelete" hide-details
                    ></NumberTextField>
                  </td>
                  <td>
                    <NumberTextField
                          v-model.number="moveFcCalc"
                          readonly hide-details
                    ></NumberTextField>
                  </td>
                </tr>
                <tr>
                  <th>内 自動倉庫：㎥</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.autoF"
                          :readonly="isDelete" hide-details
                    ></NumberTextField>
                  </td>
                  <th>内 自動倉庫：㎥</th>
                  <td>
                    <NumberTextField
                          v-model.number="memberData.autoC"
                          :readonly="isDelete" hide-details
                    ></NumberTextField>
                  </td>
                  <td>
                    <NumberTextField
                          v-model.number="autoFcCalc"
                          readonly hide-details
                    ></NumberTextField>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <NumberTextField
                label="製氷能力"
                v-model="memberData.iceCapability"
                dense :readonly="isDelete" :loading="loading"
                class="right-input"
          ></NumberTextField>
        </v-col>
        <v-col cols="2">
          <NumberTextField
                label="凍結能力"
                v-model="memberData.frozenCapability"
                dense :readonly="isDelete" :loading="loading"
                class="right-input"
          ></NumberTextField>
        </v-col>
        <v-col cols="2">
          <v-select
                label="陸水区分"
                v-model="memberData.landWater"
                :items="landWater"
                dense :readonly="isDelete" :loading="loading"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea label="備考" v-model="memberData.remarks" dense :readonly="isDelete" rows="2" :maxlength="30000" :counter="30000">
          </v-textarea>
        </v-col>
      </v-row>

      <div v-if="mode != 'new'">
      <v-row>
        <v-col cols="2">
          <h4>ユーザ/連絡先</h4>
        </v-col>
        <!-- ユーザ編集へ遷移できるのは、会員のみ -->
        <v-col v-if="!isDisp && attribute != 1 && memberData.member" class="pt-0" cols="10">
          <v-btn small class="primary" :height="40" :width="100"
                :disabled="loading" @click="dispUserList()"
          >ユーザ編集</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0" cols="12">
          <v-data-table
              :items="memberData.memberList"
              :headers="headers"
              hide-default-footer
              disable-pagination
              dense
              class="mt-5"
          >
            <template v-slot:item="{ item, index }">
              <tr v-show="!item.delete" :key="index">
                <td class="center">
                  {{ item.login_flag == '1' ? '○' : '' }}
                </td>
                <td>
                  {{ item.email }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.department }}
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      </div>

      <!------------------------------------------------------------>
      <!-- 会社登録 modal -->
      <modal name="company-modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true"
                                  height="auto" width="40%" id="vm--modal">
        <div >
          <div class="modal-body">
            <McompanyEdit v-on:closeEvt="hideCompanyEdit" mode="new" companyId="" />
          </div>
        </div>
      </modal>

      <!------------------------------------------------------------>
      <!-- アクション -->
      <v-row class="mt-5 mb-5">
        <v-col cols="4">
        </v-col>
        <v-col cols="3" class="left">
          <div v-if="!isDisp">
            <v-btn v-if="!isDelete"
                  class="btn_font primary" width="200" v-on:click="registData(mode)" :disabled="loading"
            >保存</v-btn>
            <v-btn v-else
                  class="btn_font secondary" width="200" v-on:click="registData(mode)" :disabled="loading"
            >削除</v-btn>
          </div>
        </v-col>
        <v-col cols="5" class="left">
          <v-btn class="btn_font primary ml-10" width="200" v-on:click="cancel()" :disabled="loading"
        >{{!isDisp ? 'キャンセル' : '戻る'}}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import McompanyEdit from './McompanyEdit';
import DatePicker from "./common/DatePicker.vue";

import Mixin from "../mixins/const.js";
import Member from "../model/member";
import Company from "../model/m_company";
import Association from "../model/m_association";
import NumberTextField from "./common/Number_text_field.vue";

import M12city from "../model/m_12_city";
import Mdistrict from "../model/m_district";

import Message from "../Lib/Message";
import Debug from "../Lib/Debug";

export default {
  components: {
    DatePicker,
    McompanyEdit,
    NumberTextField,
  },

  mixins: [Mixin],

  data: () => ({
    valid: false,
    loading: false,

    attribute: 0,

    mode: "",           // 新規 or 編集 or 削除
    memberNo: '',
    companyId: '',

    memberList: [],

    // 新規会社or既存会社
    registType: 0,
    companyList: [],
    associationList: [],
    associationList2: [],

    selectedCompany: null,
    company: {
      companyId: null,
      companyName: "",
      companyKana: "",
      capital: null,
    },

    // テーブルヘッダー
    headers: [
      { text: "ログイン", align: "left", sortable: true, value: "login_flag", width: "100px" },
      { text: "メールアドレス", align: "left", sortable: true, value: "to", width: "300px" },
      { text: "ユーザ名/氏名", align: "left", sortable: true, value: "name", width: "300px" },
      { text: "担当部門", align: "left", sortable: true, value: "department", width: "120px" },
      // { text: "実態調査", align: "center", sortable: true, value:"", width: "100px" },
      // { text: "電力・冷媒調査", align: "center", sortable: true, value:"", width: "100px" },
      // { text: "会費請求", align: "center", sortable: true, value:"", width: "100px" },
      // { text: "お知らせ", align: "center", sortable: true, value:"", width: "100px" },
      // { text: "宛先", align: "left", sortable: true, value: "to_address", width: "400px" },
      { text: "", align: "center", sortable: true },
    ],

    // 編集ユーザデータ
    propMode: "",
    editItem: {},
    editItemIndex: null,

    // 会員データテンプレート
    memberData: {
      memberNo: "",
      companyId: "",
      companyName: "",
      companyKana: "",
      officeName: "",
      companyOfficeKana: "",
      postCode: "",
      prefId: null,
      address: "",
      bldg: "",
      tel: "",
      fax: "",
      usage: 0,
      ticket: 0,
      cityDataSection: null,
      member: 1,
      closed: 0,
      divisionCompany: 1,
      divisionIndustry1: 1,
      divisionIndustry2: 0,
      divisionIndustry3: 0,
      divisionSupporter: 0,
      divisionFrozen: 0,
      joinStart: null,
      joinEnd: null,
      associationId: null,
      associationName: null,
      frozenJoinStart: null,
      frozenJoinEnd: null,
      frozenAssociationId: "",
      frozenAssociationName: "",
      coRepresentativeName: "",
      coRepresentativeKana: "",
      coRepresentativePost: "",
      representativeName: "",
      representativePost: "",
      representativeKana: "",
      f1Volume: null,
      f2Volume: null,
      f3Volume: null,
      f4Volume: null,
      fVolume: null,
      c1Volume: null,
      c2Volume: null,
      c3Volume: null,
      cVolume: null,
      volume: null,
      moveF: null,
      moveC: null,
      moveFc: null,
      autoF: null,
      autoC: null,
      autoFc: null,
      iceCapability: null,
      frozenCapability: null,
      landWater: 0,
      groupName: "",
      completionDate: null,
      capital: null,
      url: "",
      remarks: "",
      changeCategory: 0,
      changeRemarks: "",

      memberList: [],

      // 地区
      district: null,
      kyukai: 0,
    },

    // モーダル用
    modalName: "",        // 組織(会社 or 日冷倉協 or 冷事協)
    modalSelectedId: "",  // 現在表示している組織のID(company_id or association_id)
    modalList: [],        // モーダルで選択した組織

    // 12都市区分リスト
    city12List: [],
    // 地区リスト
    districtList: [],
  }),

  // ライフサイクルフック
  created: function () {
  },

  mounted: async function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性

    // メンバー mypage の場合
    Debug.log(this.$route.path);
    Debug.log(this.attribute);
    if ((this.$route.path == '/member_edit_mypage')
          && (this.attribute) == 0)  {

      this.mode = 'disp'; // 閲覧のみ
      this.memberNo = this.$store.getters.getMemberNo;
      this.companyId = this.$store.getters.getCompanyId;
      Debug.log2("memberMypage->", this.memberNo);
      Debug.log2("companyMypage->", this.companyId);
    } else {
      // 事業所編集
      this.mode = this.$store.getters.getMode; // 新規 or 編集 or 削除をstoreから取得
      this.memberNo = this.$store.getters.getEditId;
    }

    // v-select のデータを非同期で取得
    this.loading = true;
    const promiseArray = [];
    if (this.mode == "new") {
      promiseArray.push(this.getCompanyList());
    }
    promiseArray.push(this.getAssociationList());
    promiseArray.push(this.getCity12List());
    promiseArray.push(this.getDistrictList());
    Promise.all(promiseArray);

    // ログインユーザが都道府県協会の場合
    if(this.attribute == 1) {
      // 日冷倉協項目は自身の協会をセット
      this.memberData.associationId = this.$store.getters.getAssociation.association_id;
    }

    await this.getCompanyData();
    await this.loadList();
    this.loading = false;
  },


  methods: {
    /** 表示更新 */
    async loadList() {
      Debug.log("function[loadList]");
      this.loading = true;

      // 新規以外の場合
      if (this.mode != "new") {
        // DBから会員情報取得
        await Member.getData(this.memberNo)
          .then(res => {
            if (res.data) {
              this.memberData = res.data;
              this.memberData.iceCapability = Number(this.memberData.iceCapability);
              this.memberData.frozenCapability = Number(this.memberData.frozenCapability);

              this.memberData.changeCategory = 0;
              this.memberData.changeRemarks = "";

              Debug.log2("MemberData", this.memberData);
            }
          })
          .catch((err) => {
            Message.err(err, "会員情報を取得できませんでした");
          });
      }

      this.loading=false;
    },

    async getCompanyList() {
      Debug.log("function[getCompanyList]");

      // DBから会員情報取得
      try {
        const res = await Company.getDataList("99");
        if (res.data) {
          this.companyList = res.data;

          Debug.log(this.companyList);
        }
      } catch (error) {
        Message.err(error, "会社を取得できませんでした");
      }
    },

    async getAssociationList() {
      Debug.log("function[getAssociationList]");
      // DBから協会情報取得
      Association.getDataList()
          .then(res => {
            if (res.data) {
              this.associationList = res.data.filter(aso => aso.type == '0');
              this.associationList2 = res.data.filter(aso => aso.type == '1');

              Debug.log(this.associationList);
              Debug.log(this.associationList2);
            }
          })
          .catch(err => {
              Message.err(err, "協会を取得できませんでした");
          });
    },

    async getCity12List() {
      Debug.log("function[getCity12List]");
      // DBから12都市情報取得
      M12city.selectList()
          .then(res => {
            if (res.data) {
              this.city12List = res.data;

              Debug.log(this.city12List);
            }
          })
          .catch (err => {
            Message.err(err, "12都市区分を取得できませんでした");
          });
    },

    async getDistrictList() {
      Debug.log("function[getDistrictList]");
      // DBから会員情報取得
      Mdistrict.selectList()
          .then(res => {
            if (res.data) {
              this.districtList = res.data;

              Debug.log(this.districtList);
            }
          })
          .catch(err => {
            Message.err(err, "集計地区を取得できませんでした");
          });
    },

    async getCompanyData() {
      Debug.log("function[getCompanyData]");

      // 事業所情報を取得
      const searchCondition = { companyId: this.companyId };
      await Member.search(searchCondition)
        .then(res => {
          if (res.data) {
            this.memberList = [];
            res.data.forEach(m => {
              this.memberList.push({"memberNo": m.memberNo, "officeName": m.officeName});
            });

            Debug.log2("searchCondition->", searchCondition);
            Debug.log2("memberList->", this.memberList);
          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[事業所データ]');
        })
        .finally(() => {
          // this.loading = false;
        });
    },

    dispUserList() {
      // 画面遷移の確認
      const result = confirm('事業所会員編集画面からユーザ編集画面へ移動します。\n保存されていない画面データは破棄されますが、\nよろしいでしょうか？');

      if (result) {
        // ユーザ一覧編集画面に遷移する
        const campanyId = "?company_id=" + this.memberData.companyId;

        this.$router.push('/user_list' + campanyId);
      }
    },

    // --------------------------------------------------------------
    // 会社登録 モーダル表示用
    showCompanyEdit () {
      Debug.log('function[showCompanyEdit]');
      this.$modal.show('company-modal-dialog');
    },
    hideCompanyEdit : function () {
      Debug.log('function[hideCompanyEdit]');
      this.$modal.hide('company-modal-dialog');

      // 会社情報を読み直す
      this.getCompanyList();
    },


    // --------------------------------------------------------------
    /** モーダル表示用 */
    show(name, selectedId) {
      Debug.log("function[show]");

      this.modalName = name; // 表示するモーダル名

      switch (name) {
        case "company":
        case "nichirei":
        case "reiji":
          this.modalSelectedId = selectedId; // モーダルで選択中のID
          break;
      }
      Debug.log(this.modalSelectedId);

      this.$modal.show("modal-dialog");
    },
    hide: function () {
      Debug.log("function[hide]");

      this.$modal.hide("modal-dialog");
    },

    async getNewNo(prefId) {
        // 新規の場合 member_no を設定
      if (this.mode == "new") {
        const res = await Member.getNewNo(prefId);
        this.memberData.memberNo = ('000000' + res.data).slice( -6 );

        Debug.log(this.memberData.memberNo);
      }
    },

    resetDistrict(){
      // 集計地区をリセット
      this.$set(this.memberData, "district", null);
    },

    /** computedの自動計算値を更新 */
    updateValue() {
      Debug.log("function[updateValue]");
      this.memberData.fVolume = this.fVolumeCalc;
      this.memberData.cVolume = this.cVolumeCalc;
      this.memberData.volume = this.volumeCalc;
      this.memberData.moveFc = this.moveFcCalc;
      this.memberData.autoFc = this.autoFcCalc;
    },

    /** 保存 or 削除 */
    async registData(mode) {
      Debug.log("function[saveData]");
      // Vuetify Validation
      if (!this.$refs.form.validate()) {
        return;
      }

      const modeJp = mode == "delete" ? "削除" : "保存"; // 表示する実行内容をmodeによって場合分け

      if (!confirm(modeJp + "します。よろしいですか？")) return;
      this.updateValue(); // computedの自動計算値を更新
      this.loading = true;

      // DBに会員情報登録 or 論理削除
      try {
        const res = await Member.setData(this.memberData, this.mode);

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";

          if (Array.isArray(validationMsg)) {
            validationMsg.forEach((m) => (message += m + "\n"));
          } else {
            message = validationMsg;
          }

          alert(message);
          this.loading = false;
          return;
        } else {
          alert(modeJp + "しました。");
          this.$router.back();
        }
      } catch (error) {
        Message.err(error, modeJp + "できませんでした");
      }

      this.loading = false;
    },

    /** 取消 */
    cancel() {
      Debug.log("function[cancel]");

      if (!confirm("キャンセルします。よろしいですか？")) return;

      this.$router.back();
    },

    // 会社情報の設定
    changeCompanyData(id) {
      const companyData = this.companyList.find(company => company.company_id == id);

      this.memberData.companyName = companyData.company_Name;
      this.memberData.companyKana = companyData.company_kana;
      this.memberData.capital = companyData.capital;
    },

  },

  computed: {
    isDelete() {
      // return this.mode == "delete";
      // Mypage でも Readonly にする
      return this.mode == "delete" || this.mode == "disp";
    },

    isDisp() {
      return this.mode == "disp";
    },

    /** F 計 */
    fVolumeCalc() {
      return this.memberData.f1Volume + this.memberData.f2Volume + this.memberData.f3Volume + this.memberData.f4Volume;
    },
    /** C 計 */
    cVolumeCalc() {
      return this.memberData.c1Volume + this.memberData.c2Volume + this.memberData.c3Volume;
    },
    /** FC 計 */
    volumeCalc() {
      return this.memberData.f1Volume + this.memberData.f2Volume + this.memberData.f3Volume + this.memberData.f4Volume + this.memberData.c1Volume + this.memberData.c2Volume + this.memberData.c3Volume;
    },
    /** 移動ラックFC 計 */
    moveFcCalc() {
      return this.memberData.moveF + this.memberData.moveC;
    },
    /** 自動倉庫FC 計 */
    autoFcCalc() {
      return this.memberData.autoF + this.memberData.autoC;
    },

    getFilDistrictList() {
      return this.districtList.filter(item => item.prefId == this.memberData.prefId);
    },
  },

  watch: {
    $route() {
      location.reload(); // /member_edit⇔/my_pageの画面移動時に再読み込みさせる(this.memberData.memberNoを更新するため)
    },
  },
};
</script>

<style scoped>
.row + .row {
  margin-top: 0;
}

.flex {
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: center;
}

.no-border-field ::v-deep .v-input__control > .v-input__slot::before {
  border: none !important;
}

.campany-flex {
  display: flex;
  gap: 10px 30px;
}

::v-deep .w200 {
  width: 200px;
}
::v-deep .w400 {
  width: 400px;
}

/** 倉庫情報 */
.soko-info-table ::v-deep .v-text-field {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.soko-info-table ::v-deep .v-text-field input {
  text-align: right;
}
.soko-info-table ::v-deep .v-input__control > .v-input__slot::before {
  border: none !important;
}
.soko-info-table th {
  background-color: #EEE;
  font-size: 16px !important;
  font-weight: bold;
}


</style>
